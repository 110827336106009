// local url
// export const url = "http://localhost:4053";

// live urll

//  export const url = '/api'
 export const url = 'https://sundaysforever.com/api' 

export const generateImageUrl = (path) => {
  return `https://sundaysforever-bucket.s3.ap-south-1.amazonaws.com/${path}`;
};
