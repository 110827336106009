import React, { useEffect, useState, useLayoutEffect, useContext } from "react";
import { BiBed } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import { BsPeopleFill, BsSearch } from "react-icons/bs";
import { GoSettings } from "react-icons/go";
import { FaBath } from "react-icons/fa";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { images } from "./particle/Images";
import PageBanner from "./particle/PageBanner";
import DatePicker from "react-datepicker";
import { ImLocation } from "react-icons/im";
import { BiFilterAlt } from "react-icons/bi";
import { GrClose, GrFormNext, GrFormPrevious } from "react-icons/gr";
import { HiOutlineSortDescending } from "react-icons/hi";
import HotelFilter from "./HotelFilter";
import Select from "react-select";
import { getAllHotelApi } from "../service/hotel.service";
import { errorToast } from "../utils/toast";
import { generateImageUrl } from "../service/url.service";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

import {
  getAllLocationApi,
  getAmenityApi,
  getCollectionApi,
} from "../service/home.service";
import BannerTabs from "./particle/BannerTabs";
import ReactPaginate from "react-paginate";
import { SearchContext } from "../App";

function Hotels() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [closemodal, setClosemodal] = useState();
  const [filter, setFilter] = useState(false);
  const [show, setShow] = useState(false);

  const [hotels, sethotels] = useState([]);
  const [loading, setloading] = useState(true);
  const [hotelArr, sethotelArr] = useState([]);
  const [locationArr, setLocationArr] = useState([]);
  const location = useLocation();
  const [itemOffset, setItemOffset] = useState(0);
  const [page, setPage] = useState(1);
  const itemsPerPage = 12;
  console.log(
    location.pathname,
    "const location = useLocation()const location = useLocation()"
  );
  const [searchObj, setSearchObj] = useContext(SearchContext);

  const [showrightcanvas, setShowrightcanvas] = useState(false);
  const handleCloserightcanvas = () => setShowrightcanvas(false);
  const handleShowrightcanvas = () => setShowrightcanvas(true);

  const [showsortbyrightcanvas, setShowsortbyrightcanvas] = useState(false);
  const handleCloserightsortbycanvas = () => setShowsortbyrightcanvas(false);
  const handleShowrightsortbycanvas = () => setShowsortbyrightcanvas(true);

  const [sortBy, setSortBy] = useState("Relevant");

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showLocation, setShowLocation] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const options = [
    { value: "Relevant", label: "Relevant" },
    // { value: "Date, new to old", label: "Date, new to old" },
    // { value: "Date, old to new", label: "Date, old to new" },
    { value: "low", label: "Price-Low to high" },
    { value: "high", label: "Price-High to Low" },
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  const [sortBymodal, setSortBymodal] = useState(false);
  const getIcon = (name) => {
    switch (name) {
      case "bathroom":
        return <FaBath />;
        break;
      case "people":
        return <BsPeopleFill />;
        break;
      case "bedroom":
        return <BiBed />;
        break;
      default:
        break;
    }
  };

  // ================================PAGINATION=================================================

  // useEffect(() => {
  //   console.log(hotels, "hotelshotelshotels");
  //   console.log(sortBy, "sortBysortBysortBysortBysortBysortBysortBysortBy");
  //   setloading(true);
  //   if (sortBy && hotels) {
  //     if (sortBy == "low") {
  //       let hotelTemp = hotels.sort((a, b) => {
  //         return a.price - b.price;
  //       });
  //       sethotelArr([...hotelTemp]);
  //       console.log(hotelTemp, "currentItemscurrentItems");
  //     } else if (sortBy == "high") {
  //       let hotelTemp = hotels.sort((a, b) => {
  //         return b.price - a.price;
  //       });
  //       sethotelArr([...hotelTemp]);
  //       console.log(hotelTemp, "currentItemscurrentItems");
  //     } else {
  //       sethotelArr(hotels);
  //     }
  //   } else {
  //     sethotelArr(hotels);
  //   }
  //   setloading(false);
  // }, [hotels, sortBy]);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);



  // useEffect(() => {
  //   let currentItems = hotelArr.slice(itemOffset, endOffset);
  //   setCurrentItems(currentItems);
  //   console.log(currentItems, "currentItemscurrentItems");
  //   const pageCount = Math.ceil(hotels?.length / itemsPerPage);
  //   setPageCount(pageCount);
  // }, [itemOffset]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    console.log(event.selected, "event.selected");
     setSearchParams((searchParams) => {
       searchParams.set("page", event.selected + 1);
       return searchParams;
     });
     setPage(event.selected + 1);
  };

  // =================================================================================
  useLayoutEffect(() => {
       if (searchParams.get("page")) {
         setPage(searchParams.get("page"));
         return;
       }
    window.addEventListener("resize", function updateSize() {
      setWindowWidth(window.innerWidth);
    });
    setWindowWidth(window.innerWidth);
    return () =>
      window.removeEventListener("resize", function updateSize() {
        setWindowWidth(window.innerWidth);
      });
    
  
  }, []);

  return (
    <main style={{ display: "unset" }}>
      {/* <PageBanner
        title="Best Homestays in india"
        img={images.Barlowscottage14}
        cols="col-lg-6"    <HotelFilter />
        nav
      /> */}
      <section className="bg py-3">
        {windowWidth < 992 ? (
          <div className="mobilepadnig">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="searcinputmobile"
                    onClick={() => setShow(true)}
                  >
                    <BsSearch /> <p>Search for your home near ....</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <BannerTabs />
          </div>
        )}
      </section>

      <section className="padding40 hotel-listing bg-2 bg-img3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-3">
              <div className="left sticky-top">
                <HotelFilter
                  displayHide={windowWidth < 768 ? true : false}
                  sortByData={sortBy}
                  sethotels={sethotels}
                  setloading={setloading}
                  setPageCount={setPageCount}
                  page={page}
                />
              </div>
            </div>
            <div className="col-12 col-md-9">
              <div className="title_header align-items-end witb-btn mb-3">
                <div>
                  {searchObj && searchObj?.title && searchObj?.title != "" ? (
                    <h1>{searchObj?.title}</h1>
                  ) : (
                    <h1>
                      Best Properties in <span className="brown">india</span>
                    </h1>
                  )}

                  <ul className="d-flex">
                    <li>
                      <Link to="/" className="text-dark">
                        Home&nbsp;/&nbsp;
                      </Link>
                    </li>
                    <li className="brown fw-semibold">
                      {searchObj &&
                      searchObj?.name &&
                      searchObj?.name != "" &&
                      searchObj?.name != "All"
                        ? searchObj?.name
                        : "Best Properties in india"}
                    </li>
                  </ul>
                </div>
                {windowWidth > 768 && (
                  <div className="mobilebottom">
                    <Select
                      classNamePrefix="sort-by "
                      options={options}
                      defaultValue={options[0]}
                      // value={{ label: sortBy, value: sortBy
                      //  }}
                      // defaultInputValue={sortBy}
                      autoFocus={false}
                      onChange={(e) => setSortBy(e.value)}
                      placeholder="Sort By"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        colors: {
                          ...theme.colors,
                          primary25: "#eeeeee",
                          primary: "#ddbe70",
                        },
                      })}
                    />
                  </div>
                )}
                {/* <div className="d-flex gap-3">
              <button className="btn btn-brown mb-0 py-0 fs-4 rounded" onClick={()=>setShowFilter(true)}>
                  <GoSettings />
              </button>
              <HotelFilter showFilter={showFilter} setShowFilter={setShowFilter} />
            </div> */}
              </div>
              {!loading ? (
                <>
                  <div className="row gy-4">
                    {hotels && hotels?.length > 0 ? (
                      hotels.map((hotel, index) => (
                        <div className="col-12 col-lg-4" key={index}>
                          <div className="places-box">
                            <div className="overflow-hidden image">
                              <Link to={`/property/${hotel.slug}`}>
                                <img
                                  src={generateImageUrl(hotel.mainImage)}
                                  alt=""
                                  className="w-100"
                                />
                              </Link>
                            </div>

                            <ul className="tags bg text-default">
                              <li>
                                <BiBed />
                                &nbsp;{hotel?.bedroom} Bedroom
                              </li>
                              <li>
                                <FaBath />
                                &nbsp;{hotel?.bathroom} Bathrooms
                              </li>
                              <li>
                                <BsPeopleFill />
                                &nbsp;{hotel?.guest} Guests
                              </li>
                            </ul>
                            {/* {hotel.roomAndAmenitiesServiceArr && (
                              <ul className="tags bg text-default">
                                {hotel.roomAndAmenitiesServiceArr &&
                                  hotel.roomAndAmenitiesServiceArr.map((rom, indexX) =>
                                    /^\d*\.?\d*$/.test(rom.no) ? (
                                      <li key={indexX}>
                                        {getIcon(rom.name)}
                                        &nbsp;{rom.no}{" "}
                                        {rom.name.charAt(0).toUpperCase() +
                                          rom.name.slice(1)}
                                      </li>
                                    ) : (
                                      ""
                                    )
                                  )}
                              </ul>
                            )} */}

                            <div className="content">
                              <h4>
                                <Link to={`/property/${hotel.slug}`}>
                                  {hotel.name}
                                </Link>
                              </h4>
                              <p className="mb-2 small">
                                <ImLocation className="brown" />
                                {hotel?.locationObj?.name}
                              </p>

                              <div className="d-flex align-items-center gap-2 justify-content-between">
                                <div>
                                  <div className="price">
                                    <p className="text-default mb-0 small">
                                      From &nbsp;
                                    </p>
                                    <h5 className="brown">₹{hotel.price}</h5>
                                    <p className="text-default mb-0 small">
                                      / Night
                                    </p>
                                  </div>
                                  <p className="mb-0 small">
                                    (excl. taxes & charges)
                                  </p>
                                </div>
                                <Link
                                  to={`/property/${hotel.slug}`}
                                  className="btn btn-brown-outline btn-sm"
                                >
                                  Book Now
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-12 mt-5 text-center">
                        <h4>No Properties Found</h4>
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 mt-4" id="react-paginate">
                    <ReactPaginate
                      previousLabel={<GrFormPrevious />}
                      nextLabel={<GrFormNext />}
                      breakLabel={<a href="">...</a>}
                      breakClassName={"break-me"}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      className={"pagination"}
                      activeClassName={"active"}
                      forcePage={page !== 0 ? page - 1 : 0}
                    />
                  </div>
                </>
              ) : (
                <div className="col-12 mt-5 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    style={{
                      margin: "auto",
                      background: "rgba(241, 242, 243,0)",
                      display: "block",
                    }}
                    width="100px"
                    height="100px"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                  >
                    <g transform="translate(80,50)">
                      <g transform="rotate(0)">
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="C21807"
                          fill-opacity="1"
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.875s"
                            values="1.5 1.5;1 1"
                            keyTimes="0;1"
                            dur="1s"
                            repeatCount="indefinite"
                          ></animateTransform>
                          <animate
                            attributeName="fill-opacity"
                            keyTimes="0;1"
                            dur="1s"
                            repeatCount="indefinite"
                            values="1;0"
                            begin="-0.875s"
                          ></animate>
                        </circle>
                      </g>
                    </g>
                    <g transform="translate(71.21320343559643,71.21320343559643)">
                      <g transform="rotate(45)">
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="C21807"
                          fill-opacity="0.875"
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.75s"
                            values="1.5 1.5;1 1"
                            keyTimes="0;1"
                            dur="1s"
                            repeatCount="indefinite"
                          ></animateTransform>
                          <animate
                            attributeName="fill-opacity"
                            keyTimes="0;1"
                            dur="1s"
                            repeatCount="indefinite"
                            values="1;0"
                            begin="-0.75s"
                          ></animate>
                        </circle>
                      </g>
                    </g>
                    <g transform="translate(50,80)">
                      <g transform="rotate(90)">
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="C21807"
                          fill-opacity="0.75"
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.625s"
                            values="1.5 1.5;1 1"
                            keyTimes="0;1"
                            dur="1s"
                            repeatCount="indefinite"
                          ></animateTransform>
                          <animate
                            attributeName="fill-opacity"
                            keyTimes="0;1"
                            dur="1s"
                            repeatCount="indefinite"
                            values="1;0"
                            begin="-0.625s"
                          ></animate>
                        </circle>
                      </g>
                    </g>
                    <g transform="translate(28.786796564403577,71.21320343559643)">
                      <g transform="rotate(135)">
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="C21807"
                          fill-opacity="0.625"
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.5s"
                            values="1.5 1.5;1 1"
                            keyTimes="0;1"
                            dur="1s"
                            repeatCount="indefinite"
                          ></animateTransform>
                          <animate
                            attributeName="fill-opacity"
                            keyTimes="0;1"
                            dur="1s"
                            repeatCount="indefinite"
                            values="1;0"
                            begin="-0.5s"
                          ></animate>
                        </circle>
                      </g>
                    </g>
                    <g transform="translate(20,50.00000000000001)">
                      <g transform="rotate(180)">
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="C21807"
                          fill-opacity="0.5"
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.375s"
                            values="1.5 1.5;1 1"
                            keyTimes="0;1"
                            dur="1s"
                            repeatCount="indefinite"
                          ></animateTransform>
                          <animate
                            attributeName="fill-opacity"
                            keyTimes="0;1"
                            dur="1s"
                            repeatCount="indefinite"
                            values="1;0"
                            begin="-0.375s"
                          ></animate>
                        </circle>
                      </g>
                    </g>
                    <g transform="translate(28.78679656440357,28.786796564403577)">
                      <g transform="rotate(225)">
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="C21807"
                          fill-opacity="0.375"
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.25s"
                            values="1.5 1.5;1 1"
                            keyTimes="0;1"
                            dur="1s"
                            repeatCount="indefinite"
                          ></animateTransform>
                          <animate
                            attributeName="fill-opacity"
                            keyTimes="0;1"
                            dur="1s"
                            repeatCount="indefinite"
                            values="1;0"
                            begin="-0.25s"
                          ></animate>
                        </circle>
                      </g>
                    </g>
                    <g transform="translate(49.99999999999999,20)">
                      <g transform="rotate(270)">
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="C21807"
                          fill-opacity="0.25"
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.125s"
                            values="1.5 1.5;1 1"
                            keyTimes="0;1"
                            dur="1s"
                            repeatCount="indefinite"
                          ></animateTransform>
                          <animate
                            attributeName="fill-opacity"
                            keyTimes="0;1"
                            dur="1s"
                            repeatCount="indefinite"
                            values="1;0"
                            begin="-0.125s"
                          ></animate>
                        </circle>
                      </g>
                    </g>
                    <g transform="translate(71.21320343559643,28.78679656440357)">
                      <g transform="rotate(315)">
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="C21807"
                          fill-opacity="0.125"
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            values="1.5 1.5;1 1"
                            keyTimes="0;1"
                            dur="1s"
                            repeatCount="indefinite"
                          ></animateTransform>
                          <animate
                            attributeName="fill-opacity"
                            keyTimes="0;1"
                            dur="1s"
                            repeatCount="indefinite"
                            values="1;0"
                            begin="0s"
                          ></animate>
                        </circle>
                      </g>
                    </g>
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {windowWidth < 768 && (
        <div className="mobile_responsive">
          <div className="container">
            <div className="row">
              <div className="col-6 border-right">
                <p onClick={() => handleShowrightsortbycanvas(true)}>
                  {" "}
                  <HiOutlineSortDescending /> Sort By{" "}
                </p>
              </div>
              <div className="col-6 padding10">
                <p onClick={() => setFilter(!filter)}>
                  <BiFilterAlt /> Filters{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <Offcanvas show={showrightcanvas} onHide={handleCloserightcanvas}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="filterrightsidemodal">
        <HotelFilter sortByData={sortBy} sethotels={sethotels} />
        <div className="bntfixed">
            <div className="row align-items-center">
              <div className="col-6">
                <Link onClick={()=> setShowrightcanvas(false)}>Clear</Link>
              </div>
              <div className="col-6 text-end">
                <div className="aplybtn">
                  <Link className="btn btnfiltertab" onClick={()=> setShowrightcanvas(false)}>Apply</Link>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
      <div className={`${filter ? "show-filter" : "hide-filter"}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="closebtnname" onClick={() => setFilter(false)}>
                <GrClose />{" "}
              </div>
            </div>
          </div>
        </div>
        {/* <div  className={`show-filter`}> */}
        <HotelFilter sortByData={sortBy} sethotels={sethotels} />
        <div className="bntfixed">
          <div className="row align-items-center">
            <div className="col-6">
              {/* <Link onClick={()=> setFilter(false)}>Clear</Link> */}
            </div>
            <div className="col-6 text-end">
              <div className="aplybtn">
                <Link
                  className="btn btnfiltertab"
                  onClick={() => setFilter(false)}
                >
                  Apply
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas
        show={showsortbyrightcanvas}
        onHide={handleCloserightsortbycanvas}
        placement="bottom"
        name="bottom"
        className="boottomsheetsortyby"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Sort By</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="filterrightsidemodal">
          <div className="row">
            <div className="col-12">
              <form action="" className="borderbotmulclass">
                <ul>
                  <li>
                    <div className="radiobtn-input">
                      <label>
                        <input
                          type="radio"
                          value="Relevant"
                          checked={sortBy == "Relevant" ? true : false}
                          onChange={(e) => {
                            setSortBy(e.target.value);
                          }}
                          name="sortBy"
                          className="pe2 radbtn"
                        />
                        Relevant
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="radiobtn-input">
                      <label>
                        <input
                          type="radio"
                          value="low"
                          checked={sortBy == "low" ? true : false}
                          name="sortBy"
                          onChange={(e) => {
                            setSortBy(e.target.value);
                          }}
                          className="pe2 radbtn"
                        />
                        Price - Low to High
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="radiobtn-input">
                      <label>
                        <input
                          type="radio"
                          value="high"
                          checked={sortBy == "high" ? true : false}
                          name="sortBy"
                          className="pe2 radbtn"
                          onChange={(e) => {
                            setSortBy(e.target.value);
                          }}
                        />
                        Price - High to Low
                      </label>
                    </div>
                  </li>
                </ul>
              </form>
            </div>
          </div>
          <div className="bntfixed">
            <div className="row align-items-center">
              <div className="col-6">
                {/* <Link onClick={()=> setShowsortbyrightcanvas(false)}>Clear</Link> */}
              </div>
              <div className="col-6 text-end">
                <div className="aplybtn">
                  <Link
                    className="btn btnfiltertab"
                    onClick={() => setShowsortbyrightcanvas(false)}
                  >
                    Apply
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* ------------------------------------------ sort by modal -------------------------------------------------- */}
      {/* ------------------------------------------ sort by modal -------------------------------------------------- */}

      <Modal
        show={sortBymodal}
        size="lg"
        onHide={() => setSortBymodal(false)}
        centered
        className="hotalfiltermobile testimonial-modal sortmodal"
      >
        <div className="row py-4">
          <div className="col-11 text-center">
            <h3>Sort By</h3>
          </div>
          <div className="col-1">
            <Modal.Header
              className="align-items-start border-0 pb-0"
              closeButton
            ></Modal.Header>
          </div>
        </div>

        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <form action="" className="borderbotmulclass">
                <ul>
                  <li>
                    <div className="radiobtn-input">
                      <label>
                        <input
                          type="radio"
                          value="Relevant"
                          className="pe2 radbtn"
                        />
                        Relevant
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="radiobtn-input">
                      <label>
                        <input
                          type="radio"
                          value="Price - Low to High"
                          className="pe2 radbtn"
                        />
                        Price - Low to High
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="radiobtn-input">
                      <label>
                        <input
                          type="radio"
                          value="option1"
                          className="pe2 radbtn"
                        />
                        Price - High to Low
                      </label>
                    </div>
                  </li>
                </ul>
              </form>
            </div>
          </div>
          <div className="bntfixed">
            <div className="row align-items-center">
              <div className="col-6">
                {/* <Link onClick={()=> setSortBymodal(false)}>Clear</Link> */}
              </div>
              <div className="col-6 text-end">
                <div className="aplybtn">
                  <Link
                    className="btn btnfiltertab"
                    onClick={() => setSortBymodal(false)}
                  >
                    Apply
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        size="lg"
        onHide={() => setShow(false)}
        centered
        className="experience-modal testimonial-modal"
      >
        <Modal.Header
          className="align-items-start border-0 pb-0"
          closeButton
        ></Modal.Header>
        <Modal.Body className="p-0">
          <div className="row">
            <div className="col-12 col-md-6">
              {windowWidth < 992 && (
                <BannerTabs className="home-banner-tabs " />
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
}

export default Hotels;
