export const GA_MEASUREMENT_ID = 'GTM-TK6MH8BV';

export const indianStatesArr = [
  { name: "Andaman and Nicobar Islands" },
  { name: "Andhra Pradesh" },
  { name: "Arunachal Pradesh" },
  { name: "Assam" },
  { name: "Bihar" },
  { name: "Chhattisgarh" },
  { name: "Chandigarh" },
  { name: "Chandigarh" },
  { name: "Chhattisgarh" },
  { name: "Daman and Diu" },
  { name: "Delhi" },
  { name: "Dadra and Nagar Haveli" },
  { name: "Dadra and Nagar Haveli" },
  { name: "Dadra and Nagar Haveli and Daman and Diu" },
  { name: "Goa" },
  { name: "Gujarat" },
  { name: "Gujarat" },
  { name: "Himachal Pradesh" },
  { name: "Haryana" },
  { name: "Jharkhand" },
  { name: "Jammu and Kashmir" },
  { name: "Karnataka" },
  { name: "Kerala" },
  { name: "Karnataka" },
  { name: "Ladakh" },
  { name: "Lakshadweep" },
  { name: "Lakshadweep" },
  { name: "Maharashtra" },
  { name: "Maharashtra" },
  { name: "Mizoram" },
  { name: "Meghalaya" },
  { name: "Manipur" },
  { name: "Madhya Pradesh" },
  { name: "Nagaland" },
  { name: "Odisha" },
  { name: "Punjab" },
  { name: "Puducherry" },
  { name: "Rajasthan" },
  { name: "Sikkim" },
  { name: "Telangana" },
  { name: "Tamil Nadu" },
  { name: "Tripura" },
  { name: "Telangana" },
  { name: "Uttarakhand" },
  { name: "Uttar Pradesh" },
  { name: "Uttarakhand" },
  { name: "West Bengal" },
];
