import React, { useContext, useEffect, useState } from "react";

function ThankYou() {
 


  return (

    <main>
      <section className="ptb-50 order-complete r my-5 py-5">
        <div className="container mt-5">
          <div className="row text-center my-5 py-5">
                <div className="col-12 col-md-12 col-lg-12 mx-auto">
            <h2 className="thank-you-title">
              Thank You for Contacting us
              <span className="emoji" role="img">
                🥳
              </span>
            </h2>
         
          </div>
        </div>
        </div>
      </section>

    </main>
  );
}

export default ThankYou;
