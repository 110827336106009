import { useContext, useEffect, useMemo, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PagesToBeExempt from "../utils/PagesToBeExempt";
import { About } from "./About";
import Login from "./Auth/Login";
import Barlowscottage from "./Barlowscottage";
import BlogDetail from "./BlogDetail";
import Blogs from "./Blogs";
import CancellationPolicy from "./CancellationPolicy";
import Contact from "./Contact";
import Faq from "./Faq";
import Forpropertyowners from "./Forpropertyowners";
import Gallery from "./Gallery";
import Hilltopcottage from "./Hilltopcottage";
import Hotels from "./Hotels";
import Index from "./Index";
import Itinerary from "./Itinerary";
import Kingscottage from "./Kingscottage";
import Offers from "./Offers";
import Footer from "./particle/Footer";
import Header from "./particle/Header";
import PrivacyPolicy from "./PrivacyPolicy";
import PropertyListing from "./PropertyListing";
import PropertyListingForm from "./PropertyListingForm";
import Readerscottage from "./Readerscottage";
import Terms from "./Terms";
import Thistlehouse from "./Thistlehouse";
import Whitehill from "./Whitehill";
// import Blogs from "./Blogs";
// import BlogDetail from "./BlogDetail";
// import Hotels from "./Hotels";
// import Login from "./Auth/Login";
// import Contact from "./Contact";
// import Forpropertyowners from "./Forpropertyowners";
// import PropertyListing from "./PropertyListing";
// import Gallery from "./Gallery";
// import Offers from "./Offers";
// import Faq from "./Faq";
// import CancellationPolicy from "./CancellationPolicy";
// import PrivacyPolicy from "./PrivacyPolicy";
// import Terms from "./Terms";
import Checkout from "./Auth/Checkout";
import Testimonials from "./Testimonials";
import UserDashboard from "./UserDashboard";
import Error from "./Error";
import Booking from "./Booking";
import OldBlogDetail from "./OldBlogDetail";
import { AuthoriseContext, axiosApiInstance } from "../App";
import { getDecodedToken, getToken, refreshToken, removeToken, setToken } from "../service/user.service";
import axios from "axios";
import OrderComplete from "./OrderComplete";
import Payment from "./Payment";
import ThankYou from "./ThankYou";
// import Login from "./Aut h/Login";
export const Root = () => {
  const [hideHeader, setHideHeader] = useState(false);
  const [isAuthorized, setIsAuthorized] = useContext(AuthoriseContext);
  const navigate = useNavigate();


  const CheckIsAuthorised = async () => {
    let token = getToken();
    if (token) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  };


  useEffect(() => {
    CheckIsAuthorised();
  }, [isAuthorized]);


  let location = useLocation();
  useEffect(() => {
    // console.log(location.pathname);
    if (
      PagesToBeExempt.some(
        (el) => el === location.pathname && location.pathname !== "/"
      )
    ) {
      setHideHeader(true);
    } else {
      setHideHeader(false);
    }

    // console.log(location, "hiuhi");
    // console.log(PagesToBeExempt, "PagesToBeExempt");
  }, [location]);


  useMemo(() => {
    let token = getToken();
    // console.log(token, "token");
    axiosApiInstance.interceptors.request.use(
      async (config) => {
        // console.log(token)
        if (token) {
          config.headers["authorization"] = "Bearer " + token;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
      },
      (error) => {
        console.log(error);
        Promise.reject(error);
      }
    );
    axiosApiInstance.interceptors.response.use(
      (res) => {
        // Add configurations here
        return res;
      },
      async (err) => {
        // console.log("INterceptor error");
          let {config,response} = err;
          if(response && response.status == 401){
            if(!token){
              // errorToast("Please Login ")
              // navigate("/login")
            } 
              let decode = getDecodedToken();

                let refreshTokenResponse = await refreshToken(decode);
                if(refreshTokenResponse.data && refreshTokenResponse.data.success){
                    let refreshToken = refreshTokenResponse.data.token;
                    await  setToken(refreshToken);
                    await  new Promise(resolve => {
                      config.headers["authorization"] = "Bearer " + refreshToken;
                      // console.log(config,"configconfigconfig")
                        resolve(axios(config))
                      })
                }

              // console.log(refreshTokenResponse,"responseerror")
          }
        // logoutUser()

        return Promise.reject(err);
      }
    );
  }, [isAuthorized]);

  useEffect(() => {
    // console.log(hideHeader, "hideHeader");
  }, [hideHeader]);
  return (
    <>
      {!hideHeader && <Header />}
      <Routes>

        {
          isAuthorized == true && (
            <>
                    <Route path="/UserDashboard" element={<UserDashboard />}></Route>
                    <Route path="/Booking/:id" element={<Booking />}></Route>       
            </>
          )
        }
        <Route path="/" element={<Index />} />
        <Route path="/for-property-owners" element={<Forpropertyowners />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/Itinerary" element={<Itinerary />} />
        <Route path="/Kingscottage" element={<Kingscottage />} />
        <Route path="/property/:slug" element={<Itinerary />} />
        <Route path="/Hilltopcottage" element={<Hilltopcottage />} />
        <Route path="/Barlowscottage" element={<Barlowscottage />} />
        <Route path="/Readerscottage" element={<Readerscottage />} />
        <Route path="/Thistlehouse" element={<Thistlehouse />} />
        <Route path="/Whitehill" element={<Whitehill />}></Route>
        <Route path="/Blogs" element={<Blogs />}></Route>
        {/* <Route path="/BlogDetail" element={<BlogDetail />}></Route> */}
        <Route path="/BlogDetail/:slug" element={<OldBlogDetail />}></Route>
        <Route path="/blog/:slug" element={<BlogDetail />}></Route>
        <Route path="/properties" element={<Hotels />}></Route>
        <Route path="/our-hotels" element={<Hotels />}></Route>
        <Route path="/location/:slug" element={<Hotels />}></Route>
        <Route path="/collection/:slug" element={<Hotels />}></Route>
        <Route path="/our-homestays" element={<Hotels />}></Route>
        <Route path="/PropertyListingForm" element={<PropertyListingForm />}></Route>
        <Route path="/PropertyListing" element={<PropertyListing />}></Route>
        <Route path="/Login" element={<Login />}></Route>
        <Route path="/Contact" element={<Contact />}></Route>
        <Route path="/Gallery" element={<Gallery />}></Route>
        <Route path="/Offers" element={<Offers />}></Route>
        <Route path="/Faq" element={<Faq />}></Route>
        <Route path="/Cancellation-Policy" element={<CancellationPolicy />}></Route>
        <Route path="/Terms" element={<Terms />}></Route>
        <Route path="/Privacy-Policy" element={<PrivacyPolicy />}></Route>
        <Route path="/Checkout" element={<Checkout />}></Route>
        <Route path="/Testimonials" element={<Testimonials />}></Route>
        <Route path="/OrderComplete/:id" element={<OrderComplete />}></Route>
        <Route path="/Payment/:id" element={<Payment />}></Route>
        <Route path="/ThankYou" element={<ThankYou />}></Route>
        <Route path="/Thank"   element={ <Navigate to="/ThankYou" /> }/>
        <Route path="/Blog-Detail/the-ultimate-guide-to-budget-travel-in-india"   element={ <Navigate to="/blog/the-ultimate-guide-to-budget-travel-in-india" /> }/>
        <Route path="/Blog-Detail/5-reasons-to-choose-a-homestay-for-your-next-vacation"   element={ <Navigate to="/blog/5-reasons-to-choose-a-homestay-for-your-next-vacation" /> }/>
        <Route path="/Blog-Detail/tips-and-tricks-for-a-budget-friendly-hill-station-holiday-in-india"   element={ <Navigate to="/blog/tips-and-tricks-for-a-budget-friendly-hill-station-holiday-in-india" /> }/>
        <Route path="/Blog-Detail/the-ultimate-guide-to-budget-travel-in-india"   element={ <Navigate to="/blog/the-ultimate-guide-to-budget-travel-in-india" /> }/>
        <Route path="/Blog-Detail/the-ultimate-guide-to-the-spice-trail-of-kerala"   element={ <Navigate to="/blog/the-ultimate-guide-to-the-spice-trail-of-kerala" /> }/>
        <Route path="/Blog-Detail/escape-the-heat-10-breathtaking-hill-stations-in-india-you-must-visit-this-summer"   element={ <Navigate to="/blog/escape-the-heat-10-breathtaking-hill-stations-in-india-you-must-visit-this-summer" /> }/>
        <Route path="/Blog-Detail/naveen-talwar-shared-his-beautiful-experience-with-sundays-forever"   element={ <Navigate to="/blog/naveen-talwar-shared-his-beautiful-experience-with-sundays-forever" /> }/>
        <Route path="/Blog-Detail/the-ultimate-guide-to-the-holi-festival-celebrating-the-festival-of-colors-in-india"   element={ <Navigate to="/blog/the-ultimate-guide-to-the-holi-festival-celebrating-the-festival-of-colors-in-india" /> }/>
        <Route path="/Blog-Detail/bhimtal-a-serene-hill-station-in-uttarakhand"   element={ <Navigate to="/blog/bhimtal-a-serene-hill-station-in-uttarakhand" /> }/>
        <Route path="/Blog-Detail/from-goa-to-andaman-a-journey-through-indias-most-beautiful-beaches"   element={ <Navigate to="/blog/from-goa-to-andaman-a-journey-through-indias-most-beautiful-beaches" /> }/>
        <Route path="/Blog-Detail/discovering-the-unique-customs-and-beliefs-of-indias-local-communities"   element={ <Navigate to="/blog/discovering-the-unique-customs-and-beliefs-of-indias-local-communities" /> }/>
        <Route path="/Blog-Detail/summer-vacation-tips-and-tricks"   element={ <Navigate to="/blog/summer-vacation-tips-and-tricks" /> }/>
        <Route path="/Blog-Detail/discover-the-best-kept-secrets-of-indias-hill-stations-a-travelers-guide"   element={ <Navigate to="/blog/discover-the-best-kept-secrets-of-indias-hill-stations-a-travelers-guide" /> }/>
        <Route path="/Blog-Detail/best-hill-stations-in-india"   element={ <Navigate to="/blog/best-hill-stations-in-india" /> }/>
        <Route path="/Blog-Detail/importance-of-supporting-local-communities-through-homestay-travel"   element={ <Navigate to="/blog/importance-of-supporting-local-communities-through-homestay-travel" /> }/>
        <Route path="/Blog-Detail/shashvat-kings-cottage-mussoorie"   element={ <Navigate to="/blog/shashvat-kings-cottage-mussoorie" /> }/>
        <Route path="/Blog-Detail/goa-a-whole-new-side"   element={ <Navigate to="/blog/goa-a-whole-new-side" /> }/>
        <Route path="/Blog-Detail/10-best-places-to-visit-in-india-between-june-and-julye"   element={ <Navigate to="/blog/10-best-places-to-visit-in-india-between-june-and-julye" /> }/>
        <Route path="/Blog-Detail/from-fear-to-fervor-how-these-travelers-overcame-their-travel-anxiety"   element={ <Navigate to="/blog/from-fear-to-fervor-how-these-travelers-overcame-their-travel-anxiety" /> }/>
        <Route path="/Blog-Detail/discovering-indias-hidden-gem-the-hampi-ruins"   element={ <Navigate to="/blog/discovering-indias-hidden-gem-the-hampi-ruins" /> }/>
        
        <Route path="*" element={<Error />}></Route>
      </Routes>
      {!hideHeader && <Footer />}
    </>
  );
};
