import React, { useState } from "react";

function Terms() {
  return (
    <main className="border-top pt-5 bg-img3">
      <section className="pb-40 policy">
        <div className="container">
          <div className="title_header mb-4">
            <h2>Terms & Conditions</h2>
          </div>
          <div className="content">
            <p>
              This document is an electronic record in terms of the Information Technology Act 2000 (as amended in 2008) and rules made therein as applicable and the amended provisions pertaining to electronic records in various statues as amended by the Information Technology Act. This electronic record is generated by a computer system and does not require any physical or digital signature and the same has been incorporated by reference in the Term of Use below.
            </p>
            <p>

              This page provides information about who we are and the legal Terms of Use that may apply to your use of www.sundaysforever.com and any bookings/reservations you make through us and/or avail any of our services.
            </p>


            <p>
              Please read these Terms of Use, Privacy Policy and other policies carefully before you start to use the Website.

            </p>
            <p>
              Website / Site

            </p>
            <p>
              This website is owned and operated by Sundays Forever Stays Private Limited unless we say otherwise, we use the terms “Sundays Forever”, “SF”, “we” and “us” on this Website to refer to Sundays Forever throughout the Website Terms of Use, which include our Terms of Use, Privacy Policy & other policies displayed on Website (collectively referred to as “Terms of Use”).

            </p>

            <p>
              If you want to ask us anything (whether about our services, this website, any of our Terms of Use or otherwise), then please contact us.

            </p>

            <p>
              Website Terms

            </p>

            <p>
              The Terms of Use apply to your use of the SF website at www.sundaysforever.com and to any services you purchase from SF. The Terms of Use apply regardless of how you access the website, including any technologies or devices by which SF makes the website available to you. You must read these Terms of Use carefully. By accessing, browsing, using, registering with, or placing an order on the website, you confirm that you have read, understood and agree to these Terms of Use in their entirety. If you do not agree to these Terms of Use in their entirety, please do not use this Website.

            </p>

            <p>
              All legal notices on this website which relate to your use of the website together with all applicable Terms of Use and our Privacy and other policies govern your use of this Website.
            </p>
            <p>

              The Agreement is governed by the provisions of Indian law, including, but not limited to:

            </p>

            <p>

              Indian Contract Act, 1872;

            </p>

            <p>
              Information Technology Act, 2000;

            </p>
            <p>
              Rules, regulations, guidelines and clarifications framed thereunder, including the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (“SPI Rules”) and; Information Technology (Intermediaries Guidelines) Rules, 2011 (“IG Rules”).

            </p>
            <p>
              Use of this Website

            </p>
            <p>
              You may only use this Website for lawful purposes, and you may not use it in a way that infringes the rights of anyone else or that restricts or inhibits anyone else’s enjoyment of the Website.
            </p>

            <p>

              You may not, without our prior written consent, copy, reproduce, crawl, frame, republish, download, print, post, distribute, re-post, broadcast, record, transmit, edit, communicate to the public, link to, deep-link into, or distribute in any way the web pages or materials on the website or the computer codes or elements comprising the website other than solely for your own personal or internal business use. You may not use the content of the website for any commercial purposes whatsoever.

            </p>
            <p>

              Ownership of Rights

            </p>
            <p>
              All rights, including copyright and other intellectual property rights, in and to this Website are owned by or licensed to SF.

            </p>

            <p>

              Your use of the Website and its contents grants no rights to you in relation to our intellectual property rights, or that of third parties, in the Website or its contents. By submitting information (other than your personal data), text, photos, videos, graphics or other content to the website you confirm that you have the right to use the same and grant us a right to use such materials at our own discretion (with or without accreditation) in any media including, without limitation, to edit, copy, reproduce, disclose, post and remove such materials from the Website.

            </p>
            <p>
              You further agree to execute all such documents and do all such acts and things as we may reasonably require in order to assign any such rights to us.

            </p>
            <p>
              Ownership of Submissions / Comments

            </p>
            <p>
              Other than personal / sensitive data (which is subject to our Privacy Policy) all comments, blogs, feedback, suggestions, pictures, videos, questions, ideas, artwork, images, product or marketing ideas and any other submissions disclosed, submitted or offered to SF on or through the Website or otherwise disclosed, submitted or offered by you (collectively, “Submissions/Comments”) shall become and remain the property of SF once submitted. You must comply with the content standards set out in these terms. You warrant that you own or have the right to use any submissions and that your submissions will comply with these standards and guidelines.
            </p>
            <p>
              Social networking sites

            </p>
            <p>

              These Website Terms also govern any submissions you make on any SF related third party website or page such as our Facebook page, Instagram, Twitter, Pinterest, YouTube or social networking site. All comments, images, videos and any other type of material posted on any third party’s social networking site do not necessarily reflect the opinions or ideas of SF or its employees and SF is not responsible for any such content. In any event, all material posted on any third-party social networking site must comply with these Website Terms and the third party social networking sites’ Terms of Use, as applicable.


            </p>
            <p>
              Intellectual Property Rights

            </p>
            <p>
              A disclosure, comments and your agreement to these Website Terms shall constitute an assignment to SF of any intellectual property rights. Whenever you make use of a feature that allows you to upload material to our site, including questions, pictures, videos etc. or to make contact with other users of our site or to make submissions on any SF related third-party website or page, you must comply with the content standards set out below and these Website Terms. Any third-party advertising on SF pages of third-party social networking sites are not the responsibility of, or endorsed by SF. All rights, including copyright on SF Website pages are owned by or licensed to SF. Any use of any SF social networking pages or their contents, including copying or storing them in whole or in part, other than for your own personal, non-commercial use is prohibited without the permission of SF. By making a submission you confirm that the submission is your own and that the content does not infringe the material, trademarks or intellectual property of others.

            </p>
            <p>
              Confidentiality

            </p>
            <p>
              Any material you upload to our website will be considered non-confidential and non-proprietary, and we have the right to use, copy, distribute and disclose to third parties any material in the submissions for any purpose. We also have the right to disclose your identity to any third-party who is claiming that any material posted or uploaded by you to our site or any SF related third party social networking site constitutes a violation of their intellectual property rights, or of their right to privacy. We will not be responsible, or liable to any third party, for the content or accuracy of any materials posted by you or any other user of our website. We have the right to remove any submissions you make on our Website if, in our opinion, such submission does not comply with the content standards set out below.

            </p>
            <p>

              Rules for Submissions

            </p>
            <p>
              You must be 18 years of age or older and registered to post a submission.

            </p>
            <p>

              Submissions should not include:

            </p>
            <p>

              Abusive, offensive or otherwise inappropriate language;

            </p>
            <p>
              Profanity, blasphemy, spiteful, racist, sexually explicit, sexually gratuitous or discriminatory comments or content that might be considered to be harassment, abuse or threats against the personal safety or property of others
            </p>
            <p>
              Comments about other reviewers or bloggers

            </p>
            <p>
              Remarks that repeat criminal accusations, false, defamatory or misleading statements

            </p>
            <p>
              Material which impersonates others or personal information about anyone, including yourself, such as phone numbers, postal addresses or credit/debit card numbers, etc.,
            </p>
            <p>
              Spam or advertising

            </p>
            <p>
              Third party brand names or trade marks

            </p>
            <p>
              HTML code, computer script or website URLs

            </p>
            <p>
              Availability, price, promotions or alternative ordering or delivery information

            </p>
            <p>
              Information about SF service providers

            </p>
            <p>

              An unsupported claim about the product producing an effect other than that for which it is indicated

            </p>
            <p>
              References to seasonal products which are no longer in season

            </p>
            <p>
              References to any products other than the product to which the submission relates.

            </p>
            <p>
              SF, in its absolute discretion, reserves the right to not publish the submission or remove it, take any appropriate action if deemed necessary or remove reviews which do not comply with the rules above.

            </p>
            <p>
              Please let us know if you see any submissions which do not comply with our rules.

            </p>
            <p>
              Accuracy of content

            </p>
            <p>
              To the extent permitted by applicable law, SF disclaims all representations and warranties, express or implied, that content or information displayed in or on this website is accurate, complete, up-to-date and/or does not infringe the rights of any third-party.

            </p>
            <p>

              The views expressed in user generated content are the opinions of those users and do not represent the views, opinions, beliefs or values of SF or any of its group companies.

            </p>
            <p>
              Damage to your computer or other devices

            </p>
            <p>
              SF uses reasonable efforts to ensure that this Website is free from viruses and other malicious or harmful content. However, we cannot guarantee that your use of this Website (including any content on it or any website accessible from it) will not cause damage to your computer or other device. It is your responsibility to ensure that you have the right equipment (including antivirus software) to use the website safely and to screen out anything that may damage or harm your computer or other device. Except where required by applicable law, SF shall not be liable to any person for any loss or damage they suffer as a result of viruses or other malicious or harmful content that they access from or via the website.

            </p>

            <p>
              Links to other websites

            </p>
            <p>
              We have placed links on this website to other websites we think you may want to visit. We do not vet these websites and do not have any control over their content. SF shall not accept any liability in respect of the content or use of these websites.
            </p>
            <p>
              Availing our Services and/or making booking or reservation through our Website

            </p>

            <p>
              You can open an account with us which will require you to provide some mandatory personal information. Alternatively, you can choose to place your order via the guest checkout.
            </p>

            <p>
              Please see our Privacy and cookie policy/other policy(s) for more information on how your personal information will be used.

            </p>

            <p>
              You confirm that all information and details provided by you to us (including on registration) are true, accurate and up to date in all respects and at all times. You can update or correct your details at any time by going to Your Account.
            </p>

            <p>
              When you create an account, we may provide you with and/or ask you to use passwords or other means to allow you to access certain areas of the website and/or to maintain your account security. It is your responsibility to maintain the confidentiality of your password and account information. SF shall not be liable to any person for any loss or damage which may arise as a result of any failure by you to protect your password or account. Should you become aware of or suspect any unauthorised use of your password or account, please contact us. If SF is suspicious of any fraudulent activity coming from your account, it can reserve the right to refuse your access to your account and delete the account.

            </p>
            <p>

              When you make a booking/reservation through the Website via our guest checkout you will still need to provide us with certain compulsory personal information for us to process your booking/reservation. We will not store the details that you provide via guest checkout (except to save it for processing your order, or if you have opted to receive marketing from us). However, you can choose to register an account with us after submitting your order. Please see our Privacy and cookie policy to see how your personal information will be used and stored.

            </p>
            <p>
              Hotel Reservation

            </p>

            <p>

              The reservations feature of the Website is provided solely to assist you/customers in determining the availability of travel related services and to make legitimate reservations and for no other purpose.

            </p>
            <p>
              You warrant that you are at least 18 years of age, possess the legal authority to enter into the legal agreement constituted by your acceptance of these conditions/policies and to use the Website in accordance with such conditions.

            </p>
            <p>
              You agree to be financially responsible for your use of the Website including without limitation for all reservations made by you or on your account for you, whether authorized by you or not. For any reservations or other services for which fees may be charged you agree to abide by the terms or conditions including without limitation payment of all moneys due under such terms or conditions.

            </p>
            <p>
              The Website contains details of hotel charges and room rates (including any available special offers) for hotels and resorts owned or managed by Sundays Forever Stays Private Ltd.
            </p>

            <p>
              Hotel reservation terms and conditions of booking are set out on the Website and payment will be in accordance with the procedure set out in such Terms of Use of the particular listing.
            </p>

            <p>

              No contract will subsist between you and Sundays Forever Stays Private Ltd or any of its affiliates in respect of any services offered through the Website unless and until Sundays Forever Stays Private Ltd accepts your order by e-mail or automated confirmation through the Website confirming that it has accepted your reservation, booking or order and any such contract shall be deemed to incorporate the hotel reservation Terms of Use of booking. Any other relevant Terms of Use relating to particular services /products are set out in the Website.

            </p>
            <p>
              You undertake that all details you provide to in connection with any services or products which may be offered by Sundays Forever Stays Private Ltd on the Website (including hotel room reservations) will be correct and, where applicable, the credit card which you use is your own and that there are sufficient funds to cover the cost of any services or products which you wish to purchase. Sundays Forever Stays Private Ltd reserves the right to obtain validation of your credit card details before providing you with any services or products.


            </p>
            <p>
              SF at its sole discretion reserves the right to not accept any booking without assigning any reason thereof.

            </p>
            <p>
              SF will not provide any service or share confirmed booking details till such time the complete consideration is received from the customer/user. SF reserves the right to refuse confirmed booking for any reasons including but not limited to behavioural issues, public safety, health hazards, infectious diseases, government orders/notification etc.

            </p>
            <p>
              Payment
            </p>
            <p>
              Subject to Payment Policy, during the checkout process, you will be asked to enter your payment details. By completing your payment details, you confirm that the credit or debit card being used is yours. All fields indicated as compulsory must be completed.

            </p>

            <p>
              You can pay for your services/booking with the payment instrument accepted by payment gateway/processor (‘’Payment Processors’) appointed by us. The processing of payments will be subject to the terms, conditions and privacy policies of the Payment Processor in addition to these Terms & Conditions. We are not responsible for error by the Payment Processor. By choosing SF, you agree to pay us, through the Payment Processor, all charges at the prices then in effect for any services including for Auto -Renewals in accordance with the applicable payment terms and you authorize us, through the Payment Processor, to charge your chosen payment provider (your “Payment Method”). You agree to make payment using that selected Payment Method. We reserve the right to correct any errors or mistakes that the Payment Processor makes even if it has already requested or received payment. If your payment is unsuccessful by reason of insufficient funds, expiration, or otherwise, you remain responsible for any uncollected amount.

            </p>
            <p>
              Payment Method. The terms of your payment will be based on your Payment Method and may be determined by agreements between you and the financial institution, credit card issuer or another provider of your chosen Payment Method. If we, through the Payment Processor, do not receive payment from you, you agree to pay all amounts due on your billing account upon demand.

            </p>

            <p>
              Changes to Your Bookings / Reservations

            </p>
            <p>
              The same shall be subject to Rescheduling, Cancellation & Refund policy available on the Website. Any changes to your order must be notified to us in writing and within a reasonable time.

            </p>
            <p>
              Legal Compliance

            </p>
            <p>
              You agree that you will not use services of SF in an unlawful manner.

            </p>

            <p>
              Exclusions of liability

            </p>

            <p>
              We exclude all representations, warranties, conditions and terms (whether express or implied by statute, law or otherwise) to the fullest extent permitted by law. We accept no liability for any losses or damages which are not reasonably foreseeable arising out of or in connection with these Terms of Use or your use of the Website.
            </p>
            <p>
              In addition, these Terms of Use shall not limit or exclude any other liability that we are not permitted to limit or exclude under applicable law.

            </p>
            <p>
              Other legal notices

            </p>
            <p>
              There may be legal notices on other areas of this website which relate to your use of the Website, all of which will, together with these Terms of Use govern your use of this Website
            </p>
            <p>
              Assignment and third party rights

            </p>
            <p>
              We may update or amend these Terms of Use from time to time to comply with law or to meet our changing business requirements without prior notification. Any updates or amendments will be posted on the website.
            </p>
            <p>

              You may not assign or sub-contract any of your rights or obligations under these Terms of Use to any third party unless we agree in writing.

            </p>
            <p>
              We may assign, transfer or sub-contract any of our rights or obligations under these Terms of Use to any third party at our discretion.

            </p>
            <p>
              Termination of Access

            </p>
            <p>
              If you breach any of these Terms of Use i.e. your license to use the Website will terminate immediately without the necessity of any notice being given to you.
            </p>

            <p>
              Otherwise access to the Website may be terminated at any time by Sundays Forever Stays Private Limited without notice.

            </p>

            <p>
              The provisions of these conditions excluding, limiting and disclaiming the liability of Sundays Forever Stays Private Limited will nevertheless survive any such termination.
            </p>

            <p>
              Waiver
            </p>

            <p>
              No relaxation or delay by us in exercising any right or remedy under these Terms of Use shall operate as waiver of that right or remedy shall affect our ability to subsequently exercise that right or remedy. Any waiver must be agreed by us in writing.
            </p>

            <p>
              Severability

            </p>

            <p>
              If any of these Terms of Use are found to be illegal, invalid or unenforceable by any court of competent jurisdiction, the rest of these Terms of Use shall remain in full force and effect.

            </p>
            <p>
              Entire Agreement

            </p>
            <p>

              These Terms of Use set out the entire agreement between you and us and supersede any and all prior terms, conditions, warranties and/or representations to the fullest extent permitted by law.
            </p>


            <p>
              Law, jurisdiction and language

            </p>
            <p>
              Any matter that arises out of your use of this website (including any contract entered between you and us through the Website) shall be governed by Indian laws as applicable and any disputes shall be subject to the exclusive jurisdiction of the courts at Gurugram, Haryana. All contracts shall be concluded in English.
            </p>

            <p>
              Notices
            </p>
            <p>

              All notices and communications shall be in writing, in English and shall be deemed given if delivered personally or by commercial messenger or courier service, or mailed by registered or certified mail (return receipt requested) or sent by email, with due acknowledgment or complete transmission to the following address:
            </p>

            <p>
              Postal Address:

            </p>

            <p>
              Email id:

            </p>
            <p>
              Grievance Officer

            </p>

            <p>

              Any grievances which you may have with respect to the services or information shared by you hereunder and its treatment, may be directed by you to the Grievance Officer of Sundays Forever i.e.
            </p>
            <p>
              Mr Nitin Bhambani, Chartered Accountant, at the below-mentioned coordinates:

            </p>

            <p>
              Mobile: 9999263717

            </p>
            <p>
              Timings: Monday to Friday: 9:00 am – 6:00 pm

            </p>
            <p>
              Changes to these Terms of Use

            </p>
            <p>
              We reserve the right to change and update these Terms of Use from time to time and recommend that you revisit this page regularly to keep informed of the current Terms of Use that apply to your use of the website. By continuing to access, browse and use this website, you will be deemed to have agreed to any changes or updates to our Terms of Use.
            </p>

            <p>
              General information

            </p>
            <p>
              We’ve designed this site with accessibility in mind. Some of the general features include:

            </p>
            <p>
              The use of clear, simple language which is easy to understand.

            </p>
            <p>
              The use of common web conventions.

            </p>


            <p>
              Interpretation
            </p>
            <p>

              Headings, subheadings, titles, subtitles to clauses, sub-clauses and paragraphs are for information only and shall not form part of the operative provisions of the Agreement and shall be ignored in construing the same.

            </p>
            <p>
              Words denoting the singular shall include the plural and words denoting any gender shall include all genders.

            </p>

            <p>
              The words “include” and “including” are to be construed without limitation.

            </p>




          </div>
        </div>
      </section>
    </main>
  );
}

export default Terms;
