import React from "react";
import { Link } from "react-router-dom";

function NewsLetter() {
  return (
    <section className="home-banner newsletter-2 mb-40">
      <div className="container">
        <div className="row align-items-center gx-lg-5">
          <div className="col-12 col-md-10">
            <h2 className="font-2 fw-bold">
            LIST YOUR VILLA WITH US!
            </h2>
            <p className="text-white">
            Join our network of 50+ happy owners and turn your villa into a high-revenue holiday destination!
            </p>
            <Link to="/PropertyListing" className="btn btn-brown rounded">
                List Your Property
                </Link>
          </div>
          {/* <div className="col-12 col-md-7">
            <form>
              <div className="d-flex gap-3">
              
              
              </div>
            </form>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default NewsLetter;
